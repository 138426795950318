<script>
export default {
  name: 'ContentCourseDetails',
  components: {
    GenericSlider: () => import('@/components/general/GenericSlider'),
    CourseEnrollModal: () => import('@/components/general/CourseEnrollModal'),
    Loading: () => import('@/components/general/Loading')
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    placeholder: {
      type: Object,
      default: () => ({})
    },
    onFavorite: {
      type: Function,
      default: null
    },
    onReady: {
      type: Function,
      default: null
    }
  },
  data () {
    const courseDetails = { ...this.placeholder, id: this.id }
    if (!courseDetails.provider) courseDetails.provider = {}
    if (!courseDetails.details) courseDetails.details = ''
    if (!courseDetails.description) courseDetails.description = ''
    return {
      openModal: false,
      relatedPositions: [],
      relatedCourses: [],
      loading: false,
      courseDetails
    }
  },
  computed: {
    buttonText () {
      return this.courseDetails.withLocalEnrollment ? this.courseDetails.enrolled ? 'discovery.details.label:details.course.access' : 'discovery.details.label:details.course.enroll' : 'discovery.details.label:details.course'
    }
  },
  methods: {
    handleEnrollCourse () {
      if (this.courseDetails.withLocalEnrollment) {
        this.openModal = true
      } else {
        window.open(this.fixedCourseUrl(this.courseDetails.url), '_blank')
      }
    },
    handleShare () {
      this.$store.dispatch('attemptSetFeedbackMsg', {
        type: 'success',
        title: this.$t('content:success.share')
      })
      navigator.clipboard.writeText(this.fixedCourseUrl(this.courseDetails.url))
    },
    toogleFavorite () {
      const isFavorite = this.courseDetails.isFavorite
      this.courseDetails.isFavorite = !isFavorite
      this.$store
        .dispatch(isFavorite ? 'attemptRemoveFavoriteCourse' : 'attemptSaveFavoriteCourse', { course_id: this.id })
        .then(() => {
          if (this.onFavorite) this.onFavorite(!isFavorite)
        })
        .catch((err) => {
          if (err.code === 403 && err.error === 'not_allowed') {
            this.showNotAllowedModal = true
          } else {
            this.directToRoute()
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('content:error.load')
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.loading = true
    Promise.all([
      this.$store.dispatch('attemptGetCourseDetailsById', { courseId: this.id, aggregator: this.getCompanyDomainAndWorkspace() }),
      this.$store.dispatch('attemptGetCourseRelatedData', { courseId: this.id, aggregator: this.getCompanyDomainAndWorkspace() })
    ]).then((data) => {
      this.courseDetails = data[0]
      this.relatedPositions = data[1]?.position || []
      this.relatedCourses = data[1]?.course || []
      if (this.onReady) this.onReady(this.courseDetails)
      this.loading = false
    })
  }
}
</script>

<template>
<v-container class="content-course-details-container mb-10">
  <div v-if="loading">
    <Loading/>
  </div>
  <div v-if="!loading">
    <div class="content-course-details--header">
      <div class="course-details-header--title">
        <v-icon class="mr-2" :color="getAccentColor">mdi-school</v-icon>
        <h2 class="mr-2" :style="{color:getAccentColor}">{{ $t('global:title.course') }}</h2>
        <v-chip class="content-course-details-numberOfPosition" v-if="courseDetails.numberOfPositions">
          <v-icon small :color="getAccentColor">mdi-briefcase</v-icon>
              <p class="ml-1 data-text-chip">{{ courseDetails.numberOfPositions }} {{ $tc('content.label.related:posiiton', courseDetails.numberOfPositions ) }}</p>
        </v-chip>
      </div>
      <div class="course-details-header--actions">
        <v-icon class="cursor-pointer mr-2" :color="getAccentColor" @click="handleShare()">mdi-share-variant</v-icon>
        <v-icon class="cursor-pointer" :color="getAccentColor" @click="toogleFavorite()">{{courseDetails.isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
      </div>
    </div>
    <div class="content-course-details--body">
      <div class="course-details-body--header">
        <div class="course-details-body--avatar mb-4">
          <v-avatar class="mr-2" :color="getAccentColor" size="30">
            <img class="avatar--icon" :src="courseDetails.provider.logo" :alt="courseDetails.provider.name"/>
          </v-avatar>
          <p class="course-details-body-currency">{{ courseDetails.provider.name }}</p>
        </div>
        <div class="course-details-body--main mb-4">
          <h5 class="course-details-body--title">{{ courseDetails.title }}</h5>
          <div class="course-details-body--info">
            <div class="course-details-item mr-4">
              <v-icon class="mr-1" :color="getAccentColor">mdi-currency-usd</v-icon>
              <p class="course-details-body-currency">{{ parseTextFormat({key: 'pricing', text: courseDetails.price, currency: courseDetails.priceCurrency}) }}</p>
            </div>
            <div class="course-details-item mr-2">
              <v-icon class="mr-1" :color="getAccentColor">mdi-web</v-icon>
              <p class="course-details-body-web">{{ parseTextFormat({key: 'modality', text: courseDetails.modality}) }}</p>
            </div>
            <div class="course-details-item" v-if="courseDetails.workload">
              <v-icon class="mr-1" :color="getAccentColor">mdi-timer</v-icon>
              <p class="course-details-body-timer">{{ parseTextFormat({key: 'workload', text: courseDetails.workload}) }}</p>
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-btn class="btn bold transform-unset mb-4" dark :color="getAccentColor" @click="handleEnrollCourse">
            {{ $t(buttonText)}}
          </v-btn>
        </v-card-actions>
      </div>
      <CourseEnrollModal @cancel="openModal=false" :course="courseDetails"  :show="openModal"/>
      <div class="course-details-body--description">
        <p class="course-details-body-title mb-2">{{ $t('global:description') }}</p>
        <p class="course-details-body-description markdown-body" v-html="this.markdownToHtml(courseDetails.details || courseDetails.description)"></p>
      </div>
      <div class="course-details-body--features rounded-pill">
        <div class="course-details-features--item">
          <span class="course-details-features-title">{{ $t('global:price') }}:</span>
          <div>
            <v-icon class="mr-2" :color="getAccentColor">mdi-currency-usd</v-icon>
            <span>{{ parseTextFormat({key: 'pricing', text: courseDetails.price, currency: courseDetails.priceCurrency}) }}</span>
          </div>
        </div>
        <div class="course-details-features--item">
          <span class="course-details-features-title">{{ $t('global:modality.title')}}:</span>
          <div>
            <v-icon class="mr-2" :color="getAccentColor">mdi-web</v-icon>
            <span>{{ parseTextFormat({key: 'modality', text: courseDetails.modality}) }}</span>
          </div>
        </div>
        <div class="course-details-features--item" v-if="courseDetails.workload">
          <span class="course-details-features-title">{{ $t('discovery.modal.search:workload.title') }}:</span>
          <div>
            <v-icon class="mr-2" :color="getAccentColor">mdi-timer</v-icon>
            <span>{{ parseTextFormat({key: 'workload', text: courseDetails.workload}) }}</span>
          </div>
        </div>
      </div>
      <v-card-actions class="course-details-body--footer">
        <v-btn class="btn bold transform-unset" dark outlined :color="getAccentColor" @click="handleShare()">
          {{ $t('course:share') }}
        </v-btn>
        <v-btn class="btn bold transform-unset" dark :color="getAccentColor" @click="handleEnrollCourse">
          {{ $t(buttonText) }}
        </v-btn>
      </v-card-actions>
       <GenericSlider v-if="relatedPositions.length" :title="$t('discovery.details.label:details.related.position')" :items="relatedPositions" type="positions" />
       <GenericSlider v-if="relatedCourses.length" :title="$t('discovery.details.label:details.another.related.course')" :items="relatedCourses" type="courses" />
    </div>
  </div>
  </v-container>
</template>
<style lang="scss">
.content-course-details-container{
  @import '../../assets/styles/markdown';
  margin-top: -20px;
  max-width: 900px;
  text-align: left;
  margin-bottom: 40px;
  .content-course-details--header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .course-details-header--title{
      display: flex;
      align-items: center;
      h2{
        font-size: 16px;
        font-weight: 800;
      }
      .v-chip {
        background: linear-gradient(0deg, rgba(98, 181, 90, 0.1), rgba(98, 181, 90, 0.1)), #FFFFFF;;
        height: 25px;
        p.data-text-chip{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 0;
        }
      }
    }
    .course-details-header--icons{
      .content-course-details-numberOfCourses {
        background: linear-gradient(0deg, rgba(98, 181, 90, 0.1), rgba(98, 181, 90, 0.1)), #FFFFFF;;
        height: 25px;
        p.data-text-chip{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 0;
        }
      }
      .content-course-details-disabledOnly {
        .v-icon {
          margin-left: 8px;
        }
        padding-left: 5px;
        background: linear-gradient(0deg, rgba(33, 150, 243, 0.1), rgba(33, 150, 243, 0.1)), #FFFFFF;
        height: 25px;
        p.data-text-chip{
          margin: 0;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #2196F3;
        }
      }
    }
    .course-details-header--actions{
      .v-btn {
        width: 81px;
        height: 32px;
      }
    }
  }
  .content-course-details--body{
    .course-details-body--header{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h5{
        margin-bottom: 16px;
      }
      .v-btn {
        height: 32px;
      }
      .course-details-body--avatar{
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar--icon {
          width: 20px;
          height: 20px;
          border-radius: 10px;
        }
        p{
          font-weight: 600;
          margin: 0;
        }
      }
      .course-details-body--main{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .course-details-body--info{
          display: flex;
          align-items: center;
          p{
            margin: 0;
          }
          .course-details-item{
            display: flex;
            .v-icon{
              font-size: 18px;
            }
          }
        }
        .upper-actions-details-body{
          display: flex;
          align-items: center;
        }
      }
    }
    .course-details-body--description{
      margin-top: 40px;
      margin-bottom: 80px;
      .course-details-body-title{
        font-weight: 800;
        font-size: 18px;
      }
    }
    .course-details-body--requirements{
      p{
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 24px;
      }
      .course-details-requirements{
        margin-bottom: 24px;
        p{
          font-weight: 700;
          font-size: 16px;
            margin-bottom: 12px;
        }
        .course-details-requirements--tags {
          display: flex;
          div {
            margin-right: 8px;
            border: 1px solid rgba(0, 0, 0, 0.06);
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
            padding: 4px 16px;
            border-radius: 16px;
            background-color: white;
          }
        }
      }
    }
    .course-details-body--features{
      display: flex;
      justify-content: space-evenly;
      margin-top: 80px;
      background-color: #FFFFFF;
      padding: 18px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      p{
        font-weight: 700;
        font-size: 18px;
      }
      .course-details-features--item{
        display: flex;
        flex-direction: column;
        align-items: center;
        .course-details-features-title{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #A0A0A0;
          margin-bottom: 16px;
          text-transform: uppercase;
          margin-bottom: 4px;
        }
        div{
          display: flex;
          align-items: center;
          .v-icon{
            font-size: 14px;
          }
        }
      }
    }
    .course-details-body--footer{
      display: flex;
      justify-content: center;
      margin: 80px 0;
    }
  }
}
</style>
